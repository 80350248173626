
<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <invoice-details />
                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <h4 class="invoice-title">Facture</h4>
                    <b-input-group
                      class="input-group-merge invoice-edit-input-group disabled"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceData.uid"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title"> Date: </span>
                    <flat-pickr
                      :config="flatPickrConfig"
                      v-model="invoiceData.issued_date"
                      class="form-control invoice-add-input"
                      placeholder="Entrer la date"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> Date Limite: </span>
                    <flat-pickr
                      :config="flatPickrConfig"
                      v-model="invoiceData.due_date"
                      class="form-control invoice-add-input"
                      placeholder="Entrer la date limite"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">Facture à :</h6>

                  <!-- Select Client -->
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="invoiceData.client"
                    :options="clients"
                    label="name"
                    :reduce="(val) => val.id"
                    input-id="invoice-data-client"
                    placeholder="Veuillez sélectionner un client"
                  >
                    <template #no-options="{ search, searching, loading }">
                      <span v-if="loading"
                        >Liste clients en cours de récupération</span
                      >
                      <span v-else-if="searching">Recherche en cours...</span>
                      <span v-else>Aucun client trouvé</span>
                    </template>
                  </v-select>

                  <!-- Selected Client -->
                  <div v-if="currClientID" class="mt-1">
                    <!-- Select client's Project -->
                    <v-select
                      v-model="invoiceData.project"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="projects"
                      label="code"
                      :reduce="(val) => val.uid"
                      input-id="invoice-data-project"
                      placeholder="Veuillez sélectionner le projet concerné"
                    >
                      <template #no-options="{ search, searching, loading }">
                        <span v-if="loading"
                          >Liste projets en cours de récupération</span
                        >
                        <span v-else-if="searching">Recherche en cours...</span>
                        <span v-else>Aucun client trouvé</span>
                      </template>
                    </v-select>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">Details de Payment:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">Total Dû:</td>
                          <td>
                            <span class="font-weight-bold">{{
                              amountToCurrency(
                                defaultInvoiceInputs.total_pretax
                              )
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">Mode de Paiement:</td>
                          <td>{{ defaultInvoiceInputs.account_name }}</td>
                        </tr>
                        <!-- <tr>
                          <td class="pr-1">Pays:</td>
                          <td>{{ defaultInvoiceInputs.account_country }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">IBAN:</td>
                          <td>{{ defaultInvoiceInputs.account_number }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">SWIFT code:</td>
                          <td>{{ defaultInvoiceInputs.swift_code }}</td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="5"> Service </b-col>
                        <b-col cols="12" lg="3"> Coût </b-col>
                        <b-col cols="12" lg="2"> Qté </b-col>
                        <b-col cols="12" lg="2"> Prix </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="5">
                          <label class="d-inline d-lg-none">Service</label>
                          <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="budgetServices"
                            label="designation"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Sélectionner un service"
                            @input="(val) => updateItemForm(index, val)"
                            :disabled="!hasProjectBudget"
                          />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label class="d-inline d-lg-none">Coût</label>
                          <b-form-input
                            v-model="item.cost"
                            type="number"
                            class="mb-2"
                            :disabled="!hasProjectBudget"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Qté</label>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                            :disabled="!hasProjectBudget"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Prix</label>
                          <p class="mb-1">{{ amountToCurrency(item.cost * item.qty) }}</p>
                        </b-col>
                        <b-col cols="12" lg="5">
                          <label class="d-inline d-lg-none">Description</label>
                          <b-form-textarea
                            v-model="item.description"
                            class="mb-2 mb-lg-0"
                            :disabled="!hasProjectBudget"
                          />
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
                :disabled="
                  !hasProjectBudget ||
                  (hasProjectBudget && budgetServices.length == 0)
                "
              >
                Ajouter Service
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Person -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                    >Émetteur de la Facture:</label
                  >
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="invoiceData.sales_person"
                    placeholder="Edward Crowley"
                  />
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Hors Taxe:</p>
                      <p class="invoice-total-amount">{{ preTaxes }}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Taxe:</p>
                      <p class="invoice-total-amount">------</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">{{ total }}</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="invoiceData.note" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: DOwnload -->
          <b-button
            @click="
              $router.push({
                name: 'apps-invoice-list',
              })
            "
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="mb-75"
            block
          >
            Annuler
          </b-button>

          <!-- Button: Print -->
          <b-button
            @click="addInvoice"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            block
            :disabled="isSubmitting"
          >
            Enregistrer
          </b-button>
        </b-card>

        <!-- Payment Method -->
        <div class="mt-2">
          <b-form-group
            label="Accepter le Paiement Via"
            label-for="payment-method"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethods"
              label="name"
              input-id="payment-method"
              class="payment-selector"
              @input="setPaymentMethod"
              :clearable="false"
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <!-- <invoice-sidebar-add-new-customer @refetch-data="fetchInvoiceProperties" /> -->
  </section>
</template>

<script>
import InvoiceDetails from "../InvoiceDetails.vue";
import { ref, computed, watch } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from '@/router'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { French } from "flatpickr/dist/l10n/fr.js";
import useInvoicesList from "../invoice-list/useInvoiceList";
import { amountToCurrency } from "@/utils/formatAmount";
import moment from "moment";
import axios from "@axios";

const requestOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
    // InvoiceSidebarAddNewCustomer,

    InvoiceDetails,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  computed: {
    getCompanyInfo() {
      let companyInfo = localStorage.getItem("COMPANY_INFO");
      return JSON.parse(companyInfo);
    },
  },
  watch: {
    currProjectID: function (newVal) {
      this.setDefaultInvoiceInputs(newVal);
      this.fetchProjectBudget(newVal);

      this.invoiceData.items = [
        JSON.parse(JSON.stringify(this.itemFormBlankItem)),
      ];

      this.initTrHeight();
    },
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      // reassign the item to the available services list
      const selectedItem = this.invoiceData.items[index];
      if (selectedItem)
        this.$store.commit(
          "invoices/UPDATE_ITEM_FROM_AVAILABLE_SERVICES",
          selectedItem.uid
        );

      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  setup() {
    // config flatpickr
    const flatPickrConfig = ref({
      wrap: true,
      altFormat: "d-m-Y",
      altInput: true,
      dateFormat: "Y-m-d",
      locale: French,
    });
    const isSubmitting = ref(false);
    const invoiceParams = ["clients", "payment_methods"]

    const {
      fetchInvoiceProperties,
      fetchClientProjects,
      fetchProjectBudget,
      isLoading,
    } = useInvoicesList();

    fetchInvoiceProperties(invoiceParams);

    const defaultInvoiceInputs = ref({
      total_pretax: 0,
      account_name: "----------",
      account_number: "----------",
      account_country: "----------",
      account_currency: "----------",
      swift_code: null,
    });

    const itemFormBlankItem = {
      designation: "",
      cost: 0,
      qty: 0,
      description: "",
    };

    const invoiceData = ref({
      id: null,
      client: null,
      project: null,
      issued_date: null,
      due_date: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      sales_person: "",
      note: "Ce fut un plaisir de travailler avec vous et votre équipe. Nous espérons que vous nous garderez à l’esprit pour vos futurs projets. Merci!",
      payment_method: null,
    });

    const preTaxes = ref(0);
    const total = ref(0);

    const currClientID = computed(() => invoiceData.value.client);
    const currProjectID = computed(() => invoiceData.value.project);

    watch(currClientID, (newVal) => {
      invoiceData.value.project = null;
      fetchClientProjects(newVal);
    });

    // Get clients list from store
    const clients = computed(() => {
      return store.getters["invoices/clients"];
    });

    // Get payment methoods list from store
    const paymentMethods = computed(() => {
      return store.getters["invoices/paymentMethods"];
    });

    // Get projects list from store
    const projects = computed(() => {
      return store.getters["invoices/projects"];
    });

    // Set default data in the invoice
    const setDefaultInvoiceInputs = (projectUID) => {
      const selectedClientProject = projects.value.find(
        (p) => p.uid == projectUID
      );

      if (selectedClientProject != undefined) {
        defaultInvoiceInputs.value.total_pretax =
          selectedClientProject.inventory.total_pretax;
      } else {
        defaultInvoiceInputs.value.total_pretax = 0;
      }
    };

    // check if project has budget by calling project budget variable and check if it has any key in the object
    const hasProjectBudget = computed(() => {
      return Object.keys(projectBudget.value).length > 0;
    });

    // Project budget
    const projectBudget = computed(() => {
      return store.getters["invoices/budget"];
    });

    // Project budget services
    const budgetServices = computed(() => {
      return store.getters["invoices/availableServices"];
    });

    const setPaymentMethod = (pm) => {
      invoiceData.value.payment_method = pm.uid; // set payment method in invoice data

      defaultInvoiceInputs.value.account_name = pm.name;
      defaultInvoiceInputs.value.account_number =
        pm.account_details?.account_number;
      defaultInvoiceInputs.value.account_country =
        pm.account_details?.account_country;
      defaultInvoiceInputs.value.account_currency =
        pm.account_details?.account_currency;
      defaultInvoiceInputs.value.swift_code = pm.account_details?.swift_code;
    };

    const updateItemForm = (index, val) => {
      const {
        cost_rate,
        qty,
        profitability,
        duty_free,
        tax_value,
        uid,
        designation,
        description,
      } = val;

      const cr = checkNumber(cost_rate);
      const quantity = checkNumber(qty);
      const profit = checkNumber(profitability);
      const pre_tax = checkNumber(duty_free);
      const tax = checkNumber(tax_value);

      invoiceData.value.items[index].uid = uid;
      invoiceData.value.items[index].designation = designation;
      invoiceData.value.items[index].cost = parseFloat(cr) * parseFloat(profit);
      invoiceData.value.items[index].qty = quantity;
      invoiceData.value.items[index].pre_tax = pre_tax;
      invoiceData.value.items[index].tax = tax;
      invoiceData.value.items[index].description = description;

      updateTrackData(index);
      store.commit("invoices/REMOVE_ITEM_FROM_AVAILABLE_SERVICES", uid);
    };

    const updateTrackData = (i) => {
      const services = invoiceData.value.items;

      // 1-/ Add pretax to total pretaxes if user enter pretax (HT) on single service
      preTaxes.value = services.reduce((acc, curr) => {
        return acc + parseFloat(curr.pre_tax);
      }, 0);

      total.value = services.reduce((acc, curr) => {
        return acc + (curr.pre_tax * (curr.tax_value != null ? curr.tax_value : 1));
      }, 0);

      // Add tax on total taxes if user choose tax on a single service
      // if (attr == "tax") {
      //   let selectedTaxes = []; // use this array to get unique taxe slug

      //   taxes.value = services.reduce((acc, curr) => {
      //     if (!selectedTaxes.includes(curr.tax)) {
      //       let currTax = findTax(curr.tax);

      //       if (currTax != undefined) {
      //         selectedTaxes.push(currTax.slug);
      //         return acc + parseFloat(currTax?.value);
      //       }
      //       return acc;
      //     }
      //     return acc;
      //   }, 0);
      // }
    };

    const checkNumber = (num, rtnVal) => {
      if (!rtnVal) rtnVal = 0;
      if (num !== null && num !== "" && num !== undefined) return num;
      return rtnVal;
    };

    const addInvoice = () => {
      isSubmitting.value = true;
      return axios
        .post("/finances/invoices", invoiceData.value, requestOptions)
        .then((response) => {
          isSubmitting.value = false;
          router.push({
            name: "apps-invoice-list",
          });
          toast({
            component: ToastificationContent,
            props: {
              title: "La facture a été enregistrée avec succès",
              icon: "CopyIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          isSubmitting.value = false;
          if (error?.message || error?.response.data?.errors) {
            const errors = error.response.data?.errors;
            for (const property in errors) {
              if (Object.hasOwnProperty.call(errors, property)) {
                const messages = errors[property].join();
                toast({
                  component: ToastificationContent,
                  props: {
                    title: messages,
                    icon: "CopyIcon",
                    variant: "danger",
                  },
                });
              }
            }
          } else {
            toast({
                  component: ToastificationContent,
                  props: {
                    title: "Une erreur est survenue lors de la mise à jour. Actualisez",
                    icon: "CopyIcon",
                    variant: "danger",
                  },
                });
          }
        });
    };

    return {
      invoiceData,
      clients,
      currClientID,
      currProjectID,
      projects,
      projectBudget,
      preTaxes,
      total,
      budgetServices,
      isSubmitting,

      defaultInvoiceInputs,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
      fetchInvoiceProperties,
      fetchClientProjects,
      fetchProjectBudget,
      setPaymentMethod,
      hasProjectBudget,
      updateTrackData,
      setDefaultInvoiceInputs,
      addInvoice,

      flatPickrConfig,
      amountToCurrency,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
